<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .header-intro-generel-box {
    .title-box {
      span {
      }
    }
  }

  .digital-marketplaces-subpage {
    position: relative;

    .img-logo-overview {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      margin-top: 30px;

      img {
        max-width: 500px;
        margin: 50px auto 40px auto;
        display: block;

        @media (max-width: $width-sm) {
          max-width: 80%;
        }
      }
    }

    .brands-overview {
      .border-right-line {
        border-right: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-right: 0;
        }
      }

      .border-left-line {
        border-left: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-left: 0;
        }
      }
      .border-bottom-line {
        border-bottom: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-bottom: 0;
        }
      }
    }

    .brands-overview-inner {
      display: block;
      //border:1px solid red;
      height: 300px;
      cursor: pointer;
      transition: $transition-std;

      @media (max-width: $width-lg) {
        height: 250px;
      }

      @media (max-width: $width-md) {
        height: 150px;
      }

      img,
      .title-text {
        display: block;
        margin: 0px auto;
        max-width: 250px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-std;

        @media (max-width: $width-lg) {
          max-width: 180px;
        }
      }

      .title-text {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }

      &:hover {
        transform: scale(0.85);

        img {
          opacity: 0.8;
        }
      }
    }

    .collapse-content {
      margin-top: 40px;

      .collapse-inner {
        background: #f1f1f1;
        padding: 25px;
      }

      .backto-overview {
        //border:1px solid blue;
        margin-bottom: 10px;
        display: block;
        position: relative;
        top: 0px;
        text-align: center;

        svg {
          width: 25px;
        }
      }

      h3 {
        text-transform: none !important;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .title-text-nachine-learning {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }

      img {
        max-width: 250px;
        display: block;
        margin: 15px auto;
      }

      .btn-contact {
        padding: 10px 0;

        a {
          display: block;
          background: $blue;
          padding: 10px 15px;
          color: #000;
          text-align: center;
          margin-right: 15px;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .btn-std {
      margin-top: 100px;
    }
  }
}
</style>

<template>
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="Styria Digital Marketplaces" />-->
  <SocialMediaIconBox />
  <div class="digital-marketplaces-subpage">
    <div class="container-fluid  img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center ">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">marketplaces</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1>Brands</h1>
        </div>
      </div>
      <div class="brands-overview row justify-content-center">
        <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
          <router-link :to="{ name: 'willhaben' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/willhaben.svg" alt="Willhaben" class="img-fluid" />
          </router-link>
        </div>
        <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line border-right-line">
          <router-link :to="{ name: 'njuskalo' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/njuskalo.svg" alt="Njuskalo" class="img-fluid" />
          </router-link>
        </div>
        <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line">
          <router-link :to="{ name: 'bolha' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/bolha.svg" alt="bolha.com" class="img-fluid" />
          </router-link>
        </div>
      </div>
      <div class="brands-overview row justify-content-center">
        <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
          <router-link :to="{ name: 'mojedelo' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/mojedelo.svg" alt="Mojedelo" class="img-fluid" />
          </router-link>
        </div>
        <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
          <router-link :to="{ name: 'njuskalo-technology' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/njuskalo-technology.png" alt="Njuskalo Technology" class="img-fluid" />
          </router-link>
        </div>
        <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line">
          <router-link :to="{ name: 'autopro24' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/autopro24.png" alt="autoPro24" class="img-fluid" />
          </router-link>
        </div>
        <div class="col-lg-4 col-md-4 justify-content-center border-left-line border-right-line">
          <router-link :to="{ name: 'dimedia' }" class="brands-overview-inner">
            <img src="@/assets/img/marken/brands/dimedia.png" alt="Dimedia" class="img-fluid" />
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'marketplaces-uebersicht' }"
              ><span class="txt">{{ $t('General.back-to-marketplaces-overview') }}</span>
              <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Brands | Styria Media Group',
      keywords: ['marketplaces', 'kleinanzeigen', 'stellenanzeigen', 'brands', 'willhaben', 'njuskalo', 'bolha', 'mojedelo'],
      description: 'willhaben, Njuškalo.hr, bolha.com, MojeDelo.com – die  Marktplätze der STYRIA sind Marktführer in ihren jeweiligen Segmenten. ',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'brands | Styria Media Group',
      keywords: ['marketplaces', 'classifieds', 'job ads', 'brands', 'willhaben', 'njuskalo', 'bolha', 'mojedelo'],
      description: 'willhaben, Njuškalo.hr, bolha.com, MojeDelo.com – the marketplaces of Styria are market leaders in their respective segments.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
