
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Brands | Styria Media Group',
      keywords: ['marketplaces', 'kleinanzeigen', 'stellenanzeigen', 'brands', 'willhaben', 'njuskalo', 'bolha', 'mojedelo'],
      description: 'willhaben, Njuškalo.hr, bolha.com, MojeDelo.com – die  Marktplätze der STYRIA sind Marktführer in ihren jeweiligen Segmenten. ',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'brands | Styria Media Group',
      keywords: ['marketplaces', 'classifieds', 'job ads', 'brands', 'willhaben', 'njuskalo', 'bolha', 'mojedelo'],
      description: 'willhaben, Njuškalo.hr, bolha.com, MojeDelo.com – the marketplaces of Styria are market leaders in their respective segments.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
